import React, { Fragment } from "react";

const Contact = props => {
  return (
    <Fragment>
      <section className="page-section">
        <div className="container">
          <h2 className="page-section-heading text-uppercase text-primary mb-0">
            Kontakt
          </h2>
          <br />
          <p className="lead">Übersicht der zentralen Kontaktdaten</p>
          <b>Villacher Saubermacher GmbH &amp; Co KG</b>
          <br />
          Drauwinkelstraße 2<br />
          9500 Villach
          <br />
          <br />
          T +43 (0) 4242 582099
          <br />
          F +43 (0) 4242 582099 - 6399
          <br />E{" "}
          <a href="mailto:villacher@saubermacher.at">
            villacher@saubermacher.at
          </a>
          <br />
          <br />
          <a
            href="https://saubermacher.at/unternehmen/standorte/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Infos zu allen Standorten
          </a>
          <br />
          Rechtsform: GmbH &amp; Co KG
          <br />
          Firmensitz: Villach
          <br />
          Firmenbuchgericht: Landesgericht Klagenfurt
          <br />
          Firmenbuchnummer: 368697b
          <br />
          UID: ATU 66722148
          <br />
          <br />
          <b>Sales Portal Support</b>
          <br />
          Dipl.-Ing. Willibald Erhart, MBA MSc
          <br />
          Hans-Roth-Straße 1, 8073 Feldkirchen
          <br />
          Telefon: +43 (0) 59 800 1510
          <br />
          E-Mail:{" "}
          <a href="mailto:w.erhart@saubermacher.at">w.erhart@saubermacher.at</a>
        </div>
      </section>
    </Fragment>
  );
};

export default Contact;
