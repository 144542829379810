import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendForm2 } from "../../actions/document";
import Alert from "../../components/layout/Alert";

const SendAddedSalesDocuments = ({
  sendForm2,
  auth: { user },
  history,
  salesdocuments
}) => {
  const [formData, setFormData1] = useState({
    mandate: "VillacherSM",
    formtype: "Dokumentenversand",
    salesmanagername: user && user.name,
    sender: user && user.email,
    position: user && user.position,
    telephone: user && user.telephone,
    recipient: "",
    comment:
      "Sehr geehrte Damen und Herren, anbei schicke ich Ihnen das gewünschte Informationsmaterial wie besprochen:",
    documents: salesdocuments
  });

  var dataAvailable = false;

  if (salesdocuments !== null) {
    if (salesdocuments.length !== 0) {
      dataAvailable = true;
    }
  }

  if (dataAvailable) {
    const {
      // eslint-disable-next-line no-unused-vars
      mandate,
      // eslint-disable-next-line no-unused-vars
      formtype,
      // eslint-disable-next-line no-unused-vars
      salesmanagername,
      // eslint-disable-next-line no-unused-vars
      sender,
      // eslint-disable-next-line no-unused-vars
      position,
      // eslint-disable-next-line no-unused-vars
      telephone,
      recipient,
      comment,
      // eslint-disable-next-line no-unused-vars
      documents
    } = formData;

    const onChange = e =>
      setFormData1({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
      e.preventDefault();
      sendForm2(formData, history);
    };
    return (
      <Fragment>
        <form className="form" onSubmit={e => onSubmit(e)}>
          <div className="control-group">
            <div className="form-group floating-label-form-group controls mb-0 pb-2">
              <label>E-Mail-Empfänger</label>
              <input
                className="form-control"
                type="text"
                placeholder="* Senden an?"
                name="recipient"
                value={recipient}
                onChange={e => onChange(e)}
              />
              <small className="form-text-primary">
                <b>* E-Mail-Empfänger (nur einer möglich!)</b>, an welche
                E-Mail-Adresse sollen die ausgewählten Vertriebsunterlagen
                gesendet werden (Versender bekommen alles CC)
              </small>
            </div>
          </div>

          <div className="control-group">
            <div className="form-group floating-label-form-group controls mb-0 pb-2">
              <label>E-Mail Vorlage</label>

              <textarea
                name="comment"
                className="form-control form-textarea"
                value={comment}
                onChange={e => onChange(e)}
              >
                Sehr geehrte Damen und Herren, anbei schicke ich Ihnen das
                gewünschte Informationsmaterial wie besprochen:
              </textarea>
              <small className="form-text">
                * E-Mail Vorlage (Links zu den Vertriebsunterlagen und
                E-Mail-Signatur wird automatisch hinzugefügt)
              </small>
            </div>
          </div>
          <Alert />
          <input type="submit" className="btn btn-primary my-1" />
        </form>
      </Fragment>
    );
  } else {
    return (
      /* show no form */
      <Fragment>
        <div></div>
      </Fragment>
    );
  }
};

SendAddedSalesDocuments.propTypes = {
  auth: PropTypes.object.isRequired,
  salesdocuments: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  sendForm2: PropTypes.func.isRequired
});

export default connect(mapStateToProps, { sendForm2 })(
  withRouter(SendAddedSalesDocuments)
);
