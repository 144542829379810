import React from "react";
import { Link } from "react-router-dom";
import CheckPermissions from '../auth/CheckPermissions';

const Footer = () => {
  return (
    <div className="completeFooter">
      <footer className="footer text-center industry-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <br />
              <br />
              <br />
              &nbsp;
              <br />
              <br />
            </div>
            <div className="moveUpOnMobile"></div>

            <div className="col-lg-4"></div>
          </div>
        </div>
      </footer>

      <section className="copyright py-4 text-center text-primary">
        <div className="container">
          <small>
            &copy; {new Date().getFullYear()} Villacher Saubermacher GmbH &amp;
            Co KG |{" "}
            <Link to="/contact" className="noUnderline">
              Kontakt
            </Link>
            <CheckPermissions>
              {" "}|{" "}
              <Link to="/register-new-user" className="noUnderline">
                Benutzer hinzufügen
              </Link>
              {" "}|{" "}

              <Link to="/show-all-users" className="noUnderline">
                Benutzer bearbeiten
              </Link>
              {" "}|{" "}

              <Link to="/upload-document" className="noUnderline">
                Dokumente hinzufügen
              </Link>
            </CheckPermissions>
          </small>
        </div>
      </section>

      <div className="scroll-to-top d-lg-none position-fixed ">
        <a
          className="js-scroll-trigger d-block text-center text-white rounded"
          href="#page-top"
        >
          <i className="fa fa-chevron-up"></i>
        </a>
      </div>
    </div>
  );
};

export default Footer;
