import React, { useState } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { uploadDocument } from "../../actions/document";
import { getCategoryName } from "../../actions/category";
import PropTypes from "prop-types";
import Alert from "../../components/layout/Alert";
import CheckPermissions from '../auth/CheckPermissions';

const UploadDocument = ({ setAlert, uploadDocument }) => {
  const [formData, setFormData] = useState({
    name: "",
    mandate: "VillacherSM",
    category_id: "",
    section: "",
    file: "",
    url: "",
  });

  const {
    name,
    category_id,
    section,
    file,
    url,
  } = formData;

  const onChange = async e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeFile = async e => {
    let value = e.target.files[0] || "";
    setFormData({ ...formData, [e.target.name]: value });
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (!validateFileOrURL()) {
      setAlert("Es muss entweder eine Datei oder ein Link angebeben werden, nicht beides", "danger");
    } else {
      uploadDocument(formData);
      //Needed to reset file
      e.target.file.value = "";
      resetFormValues();
    }
  };

  function validateFileOrURL() {
    return (file !== "" && url === "") || (file === "" && url !== "");
  }

  function resetFormValues() {
    setFormData({
      ...formData,
      name: "",
      category_id: "",
      section: "",
      file: "",
      url: "",
    });
  }


  return (
    <CheckPermissions>
      <section className="page-section">
        <div className="container">
          <br/>
          <p className="text-center lead">
            <i className="fas fa-file-upload"/> Dokumenten Upload
          </p>
          <p className="text-center">
            <small>* = Pflichtfelder</small>
          </p>
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <form className="form" onSubmit={e => onSubmit(e)}>
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="* Name"
                      name="name"
                      value={name}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                </div>
                {" "}
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Kategorie</label>
                    <select
                      className="form-control"
                      name="category_id"
                      value={category_id}
                      onChange={e => onChange(e)}
                      required
                    >
                      <option value="" disabled>* Kategorie</option>
                      {renderCategoryIdsOptions()}
                    </select>
                  </div>
                </div>
                {" "}
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Sektion</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="* Sektion"
                      name="section"
                      value={section}
                      onChange={e => onChange(e)}
                      required
                    />
                  </div>
                </div>
                {" "}
                <br/>
                <label>Es kann entweder eine Datei oder ein Link verwendet werden:</label>
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Dokument</label>
                    <input
                      className="form-control"
                      type="file"
                      name="file"
                      onChange={e => onChangeFile(e)}
                      accept="*/*"
                      disabled={url !== ""}
                    />
                  </div>
                </div>
                {" "}
                <div className="control-group">
                  <div className="form-group floating-label-form-group controls mb-0 pb-2">
                    <label>Dokument Link</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Link"
                      name="url"
                      value={url}
                      onChange={e => onChange(e)}
                      minLength="4"
                      disabled={file !== ""}
                    />
                  </div>
                </div>

                <br/>
                <Alert/>
                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-xl"
                    id="sendMessageButton"
                  >
                    Dokument hochladen
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </CheckPermissions>
  );
};

function renderCategoryIdsOptions() {
  let options = [];
  for (let catId = 1; catId <= 10; catId++) {
    options.push(<option value={catId.toString()}>{getCategoryName(catId.toString())}</option>)
  }
  return options;
}

UploadDocument.propTypes = {
  setAlert: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
};

export default connect(null, { setAlert, uploadDocument })(UploadDocument);
